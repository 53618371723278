<template>
  <div class="app-box">
    <button class="address-cancel-btn" @click="$router.back();">取消</button>
    <button class="address-finish-btn" @click="finishEvent">完成</button>
    <div ref="mapContainer" class="map-container" :style="{height:mapHeight+'px'}"></div>
    <div class="address-search-box" :style="{top:mapHeight-18+'px'}">
      <button class="address-pos-btn" @click="posEvent"></button>
      <p v-if="isAddressDown" class="address-down-icon" @click="addressListDown"></p>
      <input type="text" placeholder="搜索地点" class="search-input-text" v-model="keyWord" @focus="addressInputFocus" @input="searchEvent">
      <x-scroll-view :top="listTop" @scrollLoadData="scrollLoadData">
        <ul v-if="xhrData.length>0" class="address-list">
          <li v-for="(item,index) in xhrData" :key="index" class="address-item" :class="{'address-check':item.check}" @click="itemEvent(item);">
            <p class="address-title">{{item.title}}</p>
            <span class="address-info">{{item.address}}</span>
          </li>
        </ul>
        <x-no-data v-if="ajaxEnd&&!isNextPage"></x-no-data>
      </x-scroll-view>
    </div>
  </div>
</template>

<script>
import TMap from "TMap";
import wx from "weixin-js-sdk";
import scrollView from "@/components/scrollView";
import { page } from "@/script/mixins/page";
import noData from "@/components/noData";
import { qqMmapSuggestion, qqMapGeocoder, getJsSdkSignature } from "@/api/base";

export default {
  data () {
    return {
      timer: null,
      checkItem: null,
      center: null,
      markerLayer: null,
      map: null,
      listTop: 0.65,
      isAddressDown: false,
      keyWord: null,
      xhrData: [],
      mapHeight: 0
    };
  },
  mixins: [page],
  mounted () {
    this.wxConfig();
  },
  methods: {
    wxConfig () {
      getJsSdkSignature({
        url: window.location.href
      }).then(xhr => {
        wx.config({
          beta: true,
          debug: false,
          appId: xhr.data.appId,
          timestamp: xhr.data.timestamp,
          nonceStr: xhr.data.nonceStr,
          signature: xhr.data.signature,
          jsApiList: ["getLocation"]
        });
        this.setPos();
      });
    },
    setPos () {
      this.center = [39.984120, 116.307484];
      this.setHeight(0.65);
      this.$nextTick(() => {
        this.initMap();
      });

      // this.setHeight(0.65);
      // const that = this;
      // wx.ready(() => {
      //   wx.getLocation({
      //     type: "gcj02",
      //     success (res) {
      //       that.center = [res.latitude, res.longitude];
      //       that.$nextTick(() => {
      //         that.initMap();
      //       });
      //     },
      //     cancel (res) {
      //       alert("获取位置失败,请打开GPS功能！");
      //       console.log(res);
      //     }
      //   });
      // });
    },
    searchEvent () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.xhrData = [];
        this.ajaxEnd = false;
        if (this.keyWord) {
          this.getDataList(true, true);
        }
      }, 300);
    },
    getDataList (isReset = true, oneCheck = false) {
      qqMmapSuggestion({
        keyWord: this.keyWord,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        const listData = xhr.data.data;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          item["check"] = false;
          this.xhrData.push(item);
        });
        oneCheck && this.itemEvent(this.xhrData[0]);
        this.ajaxEnd = true;
      });
    },
    addressInputFocus () {
      this.setHeight(0.3);
      this.listTop = 1.1;
      this.isAddressDown = true;
    },
    addressListDown () {
      this.setHeight(0.65);
      this.listTop = 0.65;
      this.isAddressDown = false;
    },
    setHeight (ratio, callBack = null) {
      const doc = document.documentElement || document.body;
      this.mapHeight = Math.floor(doc.clientHeight * ratio);
      callBack && callBack();
    },
    initMap () {
      if (!this.map) {
        this.map = new TMap.Map(this.$refs["mapContainer"], {
          center: new TMap.LatLng(this.center[0], this.center[1]),
          zoom: 16,
          showControl: false
        });
      }
      this.map.setCenter(new TMap.LatLng(this.center[0], this.center[1]));
      this.getCity();
    },
    getCity () {
      if (this.$route.params.keyWord) {
        this.keyWord = this.$route.params.keyWord;
        this.getDataList(true, true);
      } else {
        qqMapGeocoder({
          latitude: this.center[0],
          longitude: this.center[1]
        }).then(xhr => {
          this.keyWord = xhr.data.result.address;
          this.getDataList(true, true);
        });
      }
    },
    itemEvent (item) {
      if (!item.check) {
        this.checkItem = item;
        this.xhrData.map(item => {
          this.$set(item, "check", false);
        });
        this.$set(item, "check", true);
        const pos = new TMap.LatLng(item.location.lat, item.location.lng);
        this.marketEvent(pos);
      }
    },
    finishEvent () {
      this.$store.commit("setSearchValue", this.checkItem);
      this.$router.back();
    },
    posEvent () {
      this.addressListDown();
      this.setPos();
    },
    marketEvent (coordinate) {
      if (this.markerLayer) {
        this.markerLayer.updateGeometries([{
          "styleId": "marker",
          "id": "1",
          "position": coordinate
        }]);
      } else {
        this.markerLayer = new TMap.MultiMarker({
          map: this.map,
          styles: {
            "marker": new TMap.MarkerStyle({
              "width": 41,
              "height": 62,
              "anchor": { x: 21, y: 62 },
              "src": require("../../../../assets/images/pos_icon.png")
            })
          },
          geometries: [{
            "id": "1",
            "styleId": "marker",
            "position": coordinate
          }]
        });
      }
      this.map.setCenter(coordinate);
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noData.name]: noData
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
}
.address-cancel-btn,
.address-finish-btn {
  position: absolute;
  top: 0.2rem;
  width: 0.5rem;
  height: 0.3rem;
  border-radius: 0.04rem;
  font-size: 0.13rem;
  color: #ffffff;
  border: 0;
  z-index: 1001;
}
.address-cancel-btn {
  left: 0.15rem;
  background: #bcc5d1;
  box-shadow: 0 0.02rem 0.04rem 0 rgba(47, 84, 134, 0.4);
}
.address-finish-btn {
  right: 0.15rem;
  background: #2b82f7;
  box-shadow: 0 0.02rem 0.04rem 0 rgba(37, 103, 193, 0.4);
}
.address-pos-btn {
  position: absolute;
  right: 0.24rem;
  top: -0.76rem;
  border: 0;
  z-index: 1001;
  width: 0.46rem;
  height: 0.46rem;
  background: url('~@images/max_pos_icon.png') no-repeat center / 0.46rem;
}
.address-search-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0.02rem 0.2rem 0 rgba(21, 66, 126, 0.1);
  border-radius: 0.08rem 0.08rem 0 0;
  z-index: 1001;
}
.address-down-icon {
  height: 0.3rem;
  padding-bottom: 0.08rem;
  background: url('~@images/address_down_icon.png') no-repeat center 0.12rem/0.36rem
    0.18rem;
}
.search-input-text {
  width: 2.88rem;
  height: 0.36rem;
  border-radius: 0.2rem;
  border: 0;
  margin: 0.12rem auto 0;
  display: block;
  background: #eff2f6 url('~@images/tab_search_icon.png') no-repeat 0.16rem
    center/0.16rem;
  padding: 0 0.16rem 0 0.41rem;
  font-size: 0.12rem;
  color: #30353d;
}
.search-input-text::-webkit-input-placeholder {
  color: #afb2b9;
}
.address-list {
  margin: auto 0.15rem;
}
.address-item {
  line-height: 1;
  margin-bottom: 0.3rem;
  padding-right: 0.2rem;
}
.address-title {
  font-size: 0.15rem;
  color: #30353d;
  margin-bottom: 0.1rem;
}
.address-info {
  font-size: 0.13rem;
  color: #b2b6bf;
}
.address-check {
  background: url('~@images/address_check_icon.png') no-repeat right
    center/0.16rem;
}
</style>

